import {
  Typography,
  Modal,
  Row,
  Col,
  Select,
  Spin,
  Button,
  message,
} from "antd";
import { useDistanceSettings } from "./store";
import { BannersTable } from "./bannersTable";
import { getDistanceAppbanner, AddBannersToDistanceSettings } from "../../api";
import { useState } from "react";
import { Option } from "antd/lib/mentions";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";

const { Title } = Typography;

export const AddBanners = () => {
  const {
    id,
    setIsAddBanners,
    isAddBanners,
    setId,
    selectedBanners,
    setSelectedBanners,
  } = useDistanceSettings();

  const resetState = () => {
    setId(null);
  };

  const { isLoading: bannersLoading, data: banners } = useQuery(
    ["distanceAppbanner", 1, 10000],
    getDistanceAppbanner,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isAddBanners,
    }
  );

  const { mutate, isLoading: addLoading } = useMutation(
    (data) => AddBannersToDistanceSettings(id, data),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("distanceAppbanner");
        } else {
          message.error(data.err);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleAddBanners = () => {
    const data = selectedBanners.map((el) => ({
      distance_setting_status: false,
      id: el.id,
    }));

    mutate(data);
  };

  return (
    <Modal
      title={<Title level={5}>Selected Banners</Title>}
      className="app-modal"
      centered
      visible={isAddBanners}
      bodyStyle={{ paddingTop: 5 }}
      onCancel={() => {
        resetState();
        setIsAddBanners(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={900}
    >
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Select
            mode="multiple"
            showSearch
            filterOption={true}
            optionFilterProp="children"
            style={{ width: "100%" }}
            placeholder="select banners to add"
            value={selectedBanners?.map((el) => el.id) || []}
            onChange={(val, option) => {
              setSelectedBanners(option.map(item => item.id));
            }}
            allowClear
          >
            {!bannersLoading ? (
              banners?.success ? (
                banners?.data?.map((el) => (
                  <Select.Option key={el.id} id={el} value={el.id}>
                    {el.name}
                  </Select.Option>
                ))
              ) : null
            ) : (
              <Spin spinning={true} />
            )}
          </Select>
        </Col>
        <Col span={24}>
          <Button
            loading={addLoading}
            onClick={handleAddBanners}
            type="primary"
          >
            Add
          </Button>
        </Col>
        <Col span={24}>
          <BannersTable id={id} />
        </Col>
      </Row>
    </Modal>
  );
};
