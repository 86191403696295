import React from "react";
import { Select, Spin } from "antd";
import { useVendor } from "./store";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { getRestaurantsNames } from "../../api";
import { useSearchParams } from "react-router-dom";

const { Option } = Select;

export default function VendorFilters() {
  const { setPage, vendorIdFilter, setVendorIdFilter } = useVendor();
  const [resturantSearch, setResturantSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let obj = {
      vendorIdFilter: Number(searchParams.get("vendorID")) || null,
      page_num: Number(searchParams.get("page_num")) || null,
    };

    setVendorIdFilter(obj.vendorIdFilter);
    setPage(obj.page_num);
  }, [searchParams.get("vendorID"), searchParams.get("page_num")]);

  const {
    isLoading: isResturantLoading,
    data: resturants,
    refetch: restRefetch,
  } = useQuery(["resturants", resturantSearch, 1], getRestaurantsNames, {
    enabled: !!resturantSearch,
    retry: 2,
    refetchOnWindowFocus: false,
  });

  return (
    <div className="filter-wrapper">
      <div className="item">
        <Select
          filterOption={false}
          dropdownMatchSelectWidth={false}
          notFoundContent={
            isResturantLoading ? (
              <div className="spin-wrapper">
                <Spin size="small" />
              </div>
            ) : null
          }
          allowClear
          showSearch
          onChange={(val) => {
            if (val !== undefined) {
              setVendorIdFilter(val);
              searchParams.set("vendorID", val);
              searchParams.set("page_num", 1);
              setSearchParams(searchParams);
            }
          }}
          onClear={() => {
            setVendorIdFilter(null);
            setResturantSearch(null);
            searchParams.delete("vendorID");
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          }}
          onSearch={(val) => {
            setResturantSearch(val);
          }}
          onClick={() => restRefetch()}
          placeholder="Select Resturants"
          style={{ width: "100%" }}
          value={vendorIdFilter}
        >
          {resturants &&
            resturants.success &&
            (resturants?.data?.records || []).map((el) => (
              <Option key={el.id} value={el.id}>
                {el.name}
              </Option>
            ))}
        </Select>
      </div>
    </div>
  );
}
