import { useEffect, useState } from "react";
import { Table, Alert, Pagination, Tag, Checkbox, Switch, message } from "antd";
import { useMutation, useQuery } from "react-query";
import {
  pageSize,
  getBannersForDistanceSetting,
  AddBannersToDistanceSettings,
} from "../../api";
import { useDistanceSettings } from "./store";
import { queryClient } from "../../queryClient";

export const BannersTable = ({ id }) => {
  const [page, setPage] = useState(1);
  const { setSelectedBanners, isAddBanners } = useDistanceSettings();

  const { mutate } = useMutation((data) =>
    AddBannersToDistanceSettings(id, data)
  );

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["distanceAppbanner", page, id], getBannersForDistanceSetting, {
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!isAddBanners,
  });

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Status",
      key: "distance_setting_status",
      dataIndex: "distance_setting_status",
      render: (status, record) => (
        <Switch
          checked={status}
          onChange={() => {
            const updatedData = data.data.map((el) => {
              if (el.id === record.id) {
                el.distance_setting_status = !el.distance_setting_status;
              }
              return {
                id: el.id,
                distance_setting_status: el.distance_setting_status,
              };
            });

            mutate(updatedData, {
              onSuccess: (data) => {
                if (data.success) {
                  message.success(data.msg);
                  queryClient.invalidateQueries("distanceAppbanner");
                } else {
                  message.error(data.err);
                }
              },
            });
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    if (data && !loading) {
      setSelectedBanners(data?.data);
    }
  }, [isAddBanners, data]);

  if (error) return <Alert message={error.message} type="error" showIcon />;

  if (!loading && !data.success)
    return <Alert message={data.msg} type="error" showIcon />;

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data?.data ? data?.data : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={true}
      />
      <br />
    </>
  );
};
