import { useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  InputNumber,
} from "antd";
import { SafetyOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useVendorPayment } from "./store";
import { useQuery } from "react-query";
import { getResturants } from "../../api";
import { ConfirmPayment } from "./confirmModal";

const { Title } = Typography;

export const CreatePayment = () => {
  const {
    isPayModal,
    setIsPayModal,
    vendorID,
    totalValue,
    setTotalValue,
    from,
    to,
    setIsConfirmModal,
    setCurrentFrom,
    setCurrentTo,
    setReceiverName,
    setPayedAmount,
  } = useVendorPayment();

  // const [ currentFrom, setCurrentFrom ] = useState(null);
  // const [ currentTo, setCurrentTo ] = useState(null);

  useEffect(() => {
    setCurrentFrom(from);
    setCurrentTo(to);
  }, [isPayModal]);

  const { data: resturants } = useQuery(
    ["resturants", "", 1, "", 10],
    getResturants,
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );

  let restname = resturants?.data?.records?.find(
    (el) => el.id == vendorID
  )?.arName;

  // const fromDateFormated = moment(date.setHours(9, 0, 0, 0)).format("YYYY-MM-DD HH:mm");
  // const toDateFormated = moment(date.setHours(23, 59, 59, 0)).format("YYYY-MM-DD HH:mm");

  // const SelectTimeDate = (value, dateString) => {
  //   let from = JSON.stringify(dateString[0]);
  //   let to = JSON.stringify(dateString[1]);

  //   if (value) {
  //     setCurrentFrom(from);
  //     setCurrentTo(to);
  //   }
  // };

  const onFinish = (values) => {
    setIsConfirmModal(true);
    setPayedAmount(values.amount);
    setReceiverName(values.receiverName);
  };

  return (
    <>
      <Modal
        title={<Title level={5}>Pay To {restname}</Title>}
        className="app-modal"
        centered
        visible={isPayModal}
        onCancel={() => {
          setIsPayModal(false);
          setTotalValue(null);
        }}
        footer={false}
        destroyOnClose={true}
        width={500}
      >
        <Form
          requiredMark={"optional"}
          name="basic"
          initialValues={{
            remember: true,
            amount: totalValue && totalValue,
          }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Row gutter={[20, 20]}>
            {/* <Col span={24}>
            <Form.Item
              label="payment From/To"
              name="date"
              // rules={[{ required: true }]}
              required={true}
            >
            <Space direction="vertical" size={5}>
              <RangePicker
                  showTime={{
                      format: "HH:mm",
                  }}
                  value={currentFrom ? [moment(currentFrom, "YYYY-MM-DD HH:mm"), moment(currentTo, "YYYY-MM-DD HH:mm")]
                      : [moment(fromDateFormated), moment(toDateFormated)]
                  }
                  defaultValue={[
                      moment(fromDateFormated),
                      moment(toDateFormated),
                  ]}
                  format="YYYY-MM-DD HH:mm"
                  onChange={SelectTimeDate}
              />
            </Space>
            </Form.Item>
          </Col> */}

            <Col span={24}>
              <Form.Item
                label="Receiver Name"
                name="receiverName"
                rules={[{ required: true }]}
              >
                <Input placeholder="Receiver Name" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Amount"
                name="amount"
                rules={[{ required: true }]}
              >
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  placeholder={"Amount"}
                  addonAfter="IQD"
                  style={{ width: "100%" }}
                // min={0}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Divider />
              <Form.Item>
                <Button
                  // loading={isLoading }
                  type="primary"
                  htmlType="submit"
                  icon={<SafetyOutlined />}
                >
                  Submit Payment
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <ConfirmPayment />
    </>
  );
};
