import React from 'react';
import { Modal } from 'antd'; 

const OrderDetails = ({visible, onCancel,productName,productDetail}) => {
 
   
  return (
    <>
      <Modal
        title="Order Details"
        visible={visible} 
        onCancel={onCancel}
        footer={null} 
      >
      <ul>
        <li>
          <h3>{productName || 'لا يوجد اسم لهذا المنتج'} </h3>
         <p> {productDetail || 'لا يوجد وصف لهذا المنتج'}</p>
        </li>
      </ul>
      </Modal>
    </>
  );
};

export default OrderDetails;
