import { useEffect } from "react";
import { Table, Typography, Alert, Pagination } from "antd";
import { useQuery } from "react-query";
import { getRestaurantsNames, pageSize } from "../../api";
import { useBikerDispatch } from "./store";

import { useSearchParams } from "react-router-dom";
const { Text } = Typography;

export const PureTable = () => {
  const {
    queryKey,
    cityIdFilter,
    setPage,
    page,
    setResturantsData,
    bikerDispatch,
  } = useBikerDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },
    {
      title: "Dispatch Time",
      dataIndex: "dispatchTime",
      key: "dispatchTime",
    },
  ];

  const { loading, data, refetch, error } = useQuery(
    ["resturants", queryKey, page, 10, cityIdFilter,],
    getRestaurantsNames,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
  useEffect(() => {
    refetch();
  }, [bikerDispatch]);

  useEffect(() => {
    if (data) {
      setResturantsData(data);
    }
  }, [data]);

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data.data.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  );
};
