import create from "zustand";

export const useOrderView = create((set) => ({
  isCreateModal: false,
  isCreateCancelModal: false,
  isEditTimeModal: false,
  order: null,
  basket: [],
  clientAddressID: null,
  clientComment: "",
  totalValue: 0,
  voucher: null,
  orderStatusHistory: [],
  isSelectReason: false,
  selectedReason: null,
  clientAdress : '',
  clientAdressName : '',
  isDetailsModal: false,

  setIsDetailsModal : (isDetailsModal) => set({ isDetailsModal }), 
  setClientAdressName : (clientAdressName) => set({ clientAdressName }), 
  setClientAdress : (clientAdress) => set({ clientAdress }),
  setIsCreateCancelModal: (isCreateCancelModal) => set({ isCreateCancelModal }),
  setIsEditTimeModal: (isEditTimeModal) => set({ isEditTimeModal }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setOrderStatusHistory: (orderStatusHistory) => set({ orderStatusHistory }),
  setVoucher: (voucher) => set({ voucher }),
  setTotalValue: (totalValue) => set({ totalValue }),
  setClientComment: (clientComment) => set({ clientComment }),
  setClientAddressID: (clientAddressID) => set({ clientAddressID }),
  setBasket: (basket) => set({ basket }),
  setOrder: (order) => set({ order }),
  setIsSelectReason: (isSelectReason) => set({ isSelectReason }),
  setSelectedReason: (selectedReason) => set({ selectedReason }),
}));
