import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  InputNumber,
  Select,
  Spin,
} from "antd";
import { message as ANTmessage } from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useSortingSettings } from "./store";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import {
  createDistanceSetting,
  createSortingSetting,
  editDistanceSetting,
  editSortingSetting,
  getDistanceSettings,
  getRestaurantsNames,
} from "../../api";
import { useEffect, useState } from "react";
import { Option } from "antd/lib/mentions";

const { Title, Link } = Typography;

export const PureCreate = () => {
  const [filter, setFilter] = useState("");

  const {
    isCreateModal,
    setIsCreateModal,
    id,
    setId,
    setPinned_vendors,
    pinned_vendors,
    setPinned_distance_limit,
    pinned_distance_limit,
    setName,
    name,
    setDistance_setting_id,
    distance_setting_id,
    setPinned_vendors_str,
    pinned_vendors_str,
  } = useSortingSettings();

  const [resturantList, setResturantList] = useState([]);

  const resetState = () => {
    setId(null);
    setName(null);
    setPinned_vendors([]);
    setPinned_distance_limit(null);
    setDistance_setting_id(null);
    setPinned_vendors_str(null);
  };

  const { isLoading: resturantLoading, data: restaurants } = useQuery(
    ["resturants", filter],
    getRestaurantsNames,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isCreateModal,
    }
  );

  const { isLoading: distanceistanceLoading, data: DistanceSettings } =
    useQuery(["sortingSettings", 1, 1000], getDistanceSettings, {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isCreateModal,
    });

  const { mutate, isLoading } = useMutation(createSortingSetting, {
    onSuccess: (data) => {
      if (data.success) {
        ANTmessage.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("sortingSettings");
        resetState();
      } else {
        ANTmessage.error(data.msg);
      }
    },
    onError: (e) => {
      ANTmessage.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editSortingSetting,
    {
      onSuccess: (data) => {
        if (data.success) {
          ANTmessage.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("sortingSettings");
          resetState();
        } else {
          ANTmessage.error(data.msg);
        }
      },
      onError: (e) => {
        ANTmessage.error(e.message);
      },
    }
  );

  useEffect(() => {
    if (isCreateModal && restaurants && restaurants?.success) {
      const vendorsObj = pinned_vendors_str ? pinned_vendors_str : {};

      const vendorsArray = Object.keys(vendorsObj).map((key) => ({
        id: Number(key),
        arName: vendorsObj[key],
      }));

      const missingVendors = vendorsArray.filter(
        (vendor) =>
          Array.isArray(restaurants?.data?.records) &&
          !restaurants.data.records.some((record) => record.id === vendor.id)
      );

      if (missingVendors.length > 0) {
        setResturantList([...restaurants?.data?.records, ...missingVendors]);
      } else {
        setResturantList(restaurants?.data?.records);
      }
    }
  }, [restaurants, pinned_vendors_str, isCreateModal]);

  const onFinish = (values) => {
    if (id) {
      editMutate({
        id: id,
        name: values.name,
        pinned_distance_limit: values.pinned_distance_limit,
        pinned_vendors: values.pinned_vendors,
        distance_setting_id: values.distance_setting_id,
      });
    } else {
      mutate({
        name: values.name,
        pinned_distance_limit: values.pinned_distance_limit,
        pinned_vendors: values.pinned_vendors,
        distance_setting_id: values.distance_setting_id,
      });
    }
  };

  return (
    <Modal
      title={
        <Title level={5}>{id ? "Edit" : "New"} Search/Nearby Settings </Title>
      }
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{
          remember: true,
          pinned_distance_limit,
          pinned_vendors,
          distance_setting_id,
          name,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input rows={4} placeholder={"Setting Name"} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Pinned Distance Limit"
              name="pinned_distance_limit"
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                addonAfter={"KM"}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Pinned Vendors"
              name="pinned_vendors"
              rules={[{ required: true }]}
            >
              <Select
                mode="multiple"
                showSearch
                filterOption={true}
                optionFilterProp="children"
                onSearch={(val) => setFilter(val)}
                style={{ width: "100%" }}
                placeholder="select vendors"
                allowClear
              >
                {!resturantLoading ? (
                  restaurants?.success ? (
                    (resturantList || []).map((el) => (
                      <Option
                        // disabled={isRepeated(el.id)}
                        key={el.id}
                        id={el.id}
                        value={el.id}
                      >
                        {el.name || el.arName}
                      </Option>
                    ))
                  ) : null
                ) : (
                  <Spin spinning={true} />
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Distance Setting"
              name="distance_setting_id"
              rules={[{ required: true }]}
            >
              <Select
                loading={distanceistanceLoading}
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="select distance setting"
                onChange={(val) => setDistance_setting_id(val)}
                options={
                  DistanceSettings &&
                  DistanceSettings?.data?.map((el) => {
                    return {
                      label: el.name,
                      value: el.id,
                    };
                  })
                }
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
