import {
    Button,
    Typography,
    Modal,
    Row,
    Col,
    Card,
    Space,
    message,
    Tag,
    Empty
  
  } from "antd";
  import { SaveOutlined } from "@ant-design/icons";
  import { useState } from "react";
  import { SortableContainer, SortableElement } from "react-sortable-hoc";
  import { arrayMoveImmutable } from "array-move";
  import { useParams } from "react-router-dom";
import { useProduct } from "./store";
import { useQuery,useMutation } from "react-query";
import { getProductGroupSorting, putProductGroupSorting } from "../../api";
  
  const { Title, Text } = Typography;

  
  export const ProductGroupSorting = () => {

    const { id } = useParams();
    const{isCreateSortModal,setIsCreateSortModal}=useProduct();
    const [list, setList] = useState([]);
 
    const { isLoading: loadingProductGroups, data: productGroupData } = useQuery(
      ["productGroupSorting",  id],
      () => getProductGroupSorting(id),
      {
        refetchOnWindowFocus: false,
        retry: 2,
        enabled: !!isCreateSortModal && !!id,
        onSuccess:(data)=>{
          setList(data?.data || [])
        },
        onError: () => {
          message.error("Failed to fetch product group sorting");
        },
      }
    );

    const { mutate: saveSorting, isLoading: savingSorting } = useMutation(
      putProductGroupSorting,
      {
        onSuccess: (data) => {
          if (data.success) {
            message.success("Sorting saved successfully!");
            setIsCreateSortModal(false); 
          } else {
            message.error("Failed to save sorting");
          }
        },
        onError: (error) => {
          message.error("Error saving sorting");
        },
      }
    );
   
    let onSortEnd = ({ oldIndex, newIndex }) => {
      setList(arrayMoveImmutable(list, oldIndex, newIndex));
    };

    const handleSave = async () => {
      const groupIDs = list.map((item) => item.id); 
     const saveData={
      id:parseInt(id),
      groupIDs:groupIDs
     }
     
     saveSorting(saveData)
     };
    
    const SortableItem = SortableElement(({ value }) => (
      <Card
        bordered={false}
        bodyStyle={{ padding: "10px 16px", backgroundColor: "#f6f6f6" }}
        style={{ marginBottom: 10 }}
      >
        <div className="app-space-between" style={{ alignItems: "center" }}>
          <Space size={16}>
            
            <Space direction="vertical" size={0}>
              <Text>{value.name}</Text>
             
            </Space>
          </Space>
        </div>
      </Card>
    ));
  
    const SortableList = SortableContainer(({ items }) => {
      return (
        <div>
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          ))}
        </div>
      );
    });
  
  
    
  
    return (
      <Modal
        title={<Title level={5}>Product Group Sorting</Title>}
        className="app-modal"
        centered
        visible={isCreateSortModal}
        bodyStyle={{ paddingTop: 5 }}
        onCancel={() => 
            setIsCreateSortModal(false)}
        footer={false}
        destroyOnClose={true}
        width={500}
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
          <div style={{ minHeight: 400, maxHeight: "70vh", overflow: "auto" }}>
            {list.length > 0 ?(
               <SortableList distance={1} items={list} onSortEnd={onSortEnd} />
            ): 
            <Card
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        textAlign: "center",
       
      }}
    >
      <Space direction="vertical" size="large">
        <Empty
          description={
            <Text type="secondary" style={{ fontSize: 16 }}>
              No data available
            </Text>
          }
        />
      </Space>
    </Card>
            }
         
            </div>
          </Col>
          <Col span={16}>
            <Button
            loading={savingSorting || loadingProductGroups}
              disabled={list.length === 0}
              type="primary"
              onClick={handleSave}
              icon={<SaveOutlined />}
            >
              Save
            </Button>
          </Col>
          <Col span={6} style={{ marginTop: "6px"  }}>
          <Tag color="pink"> Total Product Group {list.length}</Tag>
        </Col>
        </Row>
      </Modal>
    );
  };
  