import { useState, useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
  Spin,
  Space,
  Tag,
  Checkbox,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useChain } from "./store";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import { editChainVendor, getRestaurantsNames } from "../../api";

const { Title } = Typography;
const { Option } = Select;

export const PureLinkModal = () => {
  const {
    isLinkModal,
    setIsLinkModal,
    id,
    setId,
    vendors,
    setVendors,
    sync,
    setSync,
    vendorID,
    setVendorID,
  } = useChain();
  const [filter, setFilter] = useState("");
  const [selected, setSelected] = useState(null);
  const [list, setList] = useState([]);

  const {
    isLoading: resturantLoading,
    data: resturants,
    refetch,
  } = useQuery(["resturants", filter], getRestaurantsNames, {
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!isLinkModal,
  });

  useEffect(() => {
    if (filter) {
      refetch();
    }
  }, [filter]);

  useEffect(() => {
    if (vendors) {
      setList(vendors);
    }
  }, [vendors]);

  const isRepeated = (id) => (list.find((el) => el.id == id) ? true : false);

  useEffect(() => {
    if (selected && resturants?.success) {
      setList([
        ...list,
        resturants?.data?.records?.find((el) => el.id == selected),
      ]);
      setSelected(null);
    }
  }, [selected]);

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editChainVendor,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsLinkModal(false);
          queryClient.invalidateQueries("chains");
          setSync(false);
          setVendorID(null);
          setId(null);
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = () => {
    let dataForm = {
      id,
      vendorIDS: list.map((el) => el.id).join(","),
      sync,
      vendorID,
    };
    editMutate(dataForm);
  };

  return (
    <Modal
      title={<Title level={5}>Link Chain with Resturant</Title>}
      className="app-modal"
      centered
      visible={isLinkModal}
      destroyOnClose={true}
      onCancel={() => {
        setId(null);
        setIsLinkModal(false);
        setList([]);
        setSync(false);
        setVendors([]);
        setVendorID(null);
      }}
      footer={false}
      width={500}
      maskClosable={false}
    >
      <Row gutter={[10, 10]}>
        <Col flex={"100%"}>
          <Select
            showSearch
            value={selected}
            onChange={(val) => setSelected(val)}
            filterOption={true}
            optionFilterProp="children"
            onSearch={(val) => setFilter(val)}
            style={{ width: "100%" }}
            placeholder="Find Resturant"
            allowClear
          >
            {!resturantLoading ? (
              resturants?.success ? (
                resturants?.data?.records?.map((el) => (
                  <Option
                    disabled={isRepeated(el.id)}
                    key={el.id}
                    id={el.id}
                    value={el.id}
                  >
                    {el.name}
                  </Option>
                ))
              ) : null
            ) : (
              <Spin spinning={true} />
            )}
          </Select>
        </Col>
        <Col span={24}>
          <Space wrap>
            {list.length > 0
              ? list.map((el) => (
                <Tag
                  key={el.id}
                  closable
                  onClose={() => {
                    setList(list.filter((item) => item.id != el.id));
                  }}
                  color="geekblue"
                  style={{ fontSize: 16, padding: "4px 8px" }}
                >
                  {el.name || el.arName}
                </Tag>
              ))
              : null}
          </Space>
        </Col>
        <Divider />

        <Col span={25}>
          <Checkbox
            onChange={() => {
              setSync(!sync);
              if (!sync) {
                setVendorID(null);
              }
            }}
          >
            Sync Products
          </Checkbox>
        </Col>
        {sync ? (
          <Col span={24}>
            <Select
              value={vendorID}
              onChange={(val) => setVendorID(val)}
              style={{ width: "100%" }}
              placeholder="Choose synced vendor"
              allowClear
              onClear={() => setVendorID(null)}
            >
              {!resturantLoading ? (
                resturants?.success ? (
                  list?.map((el) => (
                    <Option key={el.id} id={el.id} value={el.id}>
                      {el.name || el.arName}
                    </Option>
                  ))
                ) : null
              ) : (
                <Spin spinning={true} />
              )}
            </Select>
          </Col>
        ) : null}

        <Col span={24}>
          <Divider />
          <Form.Item>
            <Button
              loading={editLoading}
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
              onClick={onFinish}
            >
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Modal>
  );
};
