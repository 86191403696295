import { useState, useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Select,
  Divider,
  InputNumber,
  Tag,
  Space,
  DatePicker,
  Spin,
  message,
  Checkbox,
  Input

} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useVendorDiscount } from "../discounts/store";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import {
  createVendorDiscount,
  editVendorDiscount,
  getProducts,
  getVendorDiscountById,
  getRestaurantsNames,
} from "../../api";
import moment from "moment";
import { CustomeCalender } from "../../comonents/global";
import dayjs from "dayjs";

const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;
export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    setId,
    isAllProduct,
    setIsAllProduct,
    setValue,
    setStartDate,
    setVendorID,
    setDiscountType,
    setEndDate,
    schedule,
    setSchedule,
    vendorID,
    setBalyValue,
    setTotalValue,
    setVendorValue,
  } = useVendorDiscount();
  const [selected, setSelected] = useState(null);
  const [selectedResturan, setSelectedResturant] = useState(null);
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState("");
  const [productsFilter, setProductsFilter] = useState("");
  const [isItemRemoved, setIsItemReomved] = useState(false);
  const [search, setSearch] = useState('')
  const [form] = Form.useForm();
  const [type] = useState("PERCENTAGE");

  const resetState = () => {
    setId(null);
    // setType(null);
    setValue(null);
    setStartDate(null);
    setVendorID(null);
    setSelectedResturant(null);
    setDiscountType(null);
    setEndDate(null);
    setSchedule(null);
    form.resetFields();
    setList([]);
    setIsAllProduct(false);
    setIsItemReomved(false);
    setBalyValue(null);
    setTotalValue(null);
    setVendorValue(null);
    setSearch('')
  };

  useEffect(() => {
    if (filter) {
      refetch();
    }
  }, [filter]);

  useEffect(() => {
    if (productsFilter) {
      productRefetch();
    }
  }, [productsFilter]);

  const {
    isLoading: resturantLoading,
    data: resturants,
    refetch,
  } = useQuery(["resturants", filter], getRestaurantsNames, {
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!isCreateModal,
  });

  const {
    isLoading: productLoading,
    data: products,
    refetch: productRefetch,
  } = useQuery(
    [
      "products",
      selectedResturan ? selectedResturan : vendorID,
      productsFilter,
    ],
    getProducts,
    {
      retry: 2,
      refetchOnWindowFocus: false,
      enabled: !!isCreateModal,
    }
  );

  const {
    isLoading: vendorDiscountLoading,
    data: vendorDiscounts,
    refetch: vendorDiscountRefetch,
  } = useQuery("vendorDiscountOne", () => getVendorDiscountById(id), {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!isCreateModal && !!id,
  });

  useEffect(() => {
    if (selectedResturan || vendorID) {
      setList([]);
      productRefetch();
    }
  }, [isCreateModal, selectedResturan, isAllProduct]);

  useEffect(() => {
    if (
      id !== null &&
      !vendorDiscountLoading &&
      vendorDiscounts &&
      vendorDiscounts.success
    ) {
      setList(vendorDiscounts?.data?.product || []);
      form.setFieldsValue({
        value: vendorDiscounts?.data?.value,
        startDate: moment(vendorDiscounts?.data?.startDate),
        endDate: moment(vendorDiscounts?.data?.endDate),
        discountType: "PERCENTAGE",
        vendorID: vendorDiscounts?.data?.vendorID,
        isAllProduct: vendorDiscounts?.data?.isAllProduct,
        totalValue: vendorDiscounts?.data?.totalValue,
        vendorValue: vendorDiscounts?.data?.vendorValue,
        balyValue: vendorDiscounts?.data?.balyValue,
        schedule: schedule,
      });
    }
  }, [id, vendorDiscounts, isCreateModal]);

  useEffect(() => {
    if (id) {
      vendorDiscountRefetch();
    } else {
      resetState();
    }
  }, [id]);

  const { mutate, isLoading } = useMutation(createVendorDiscount, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("vendorDiscounts");
        resetState();
        setIsCreateModal(false);
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editVendorDiscount,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("vendorDiscounts");
          resetState();
          setIsCreateModal(false);
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const isRepeated = (id) => (list.find((el) => el.id == id) ? true : false);

  useEffect(() => {
    if (selected && products.success) {
      setList([...list, products.data.records.find((el) => el.id == selected)]);
      setSelected(null);
    }
  }, [selected]);

  useEffect(() => {
    if (isAllProduct) {
      setList([]);
    }
  }, [isAllProduct]);

  const onFinish = (values) => {
    values.isAllProduct = isItemRemoved ? false : isAllProduct;
    values.schedule = schedule ? JSON.stringify(schedule) : "[]";
    values.productID = JSON.stringify(list.map((el) => el.id));
    values.discountType = "PERCENTAGE";
    values.startDate = dayjs(values.startDate).startOf("day");
    values.endDate = dayjs(values.endDate).startOf("day");
    values.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };

  const getVendorValue = () => {
    form.setFieldsValue({
      vendorValue:
        form.getFieldValue("totalValue") - form.getFieldValue("balyValue"),
    });
  };

  return (
    <Modal
      forceRender
      title={<Title level={5}>{id ? "Edit" : "New"} Vendor Discount</Title>}
      className="app-modal voucher-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
        // setType(null);
        queryClient.resetQueries("vendorDiscountOne");
      }}
      footer={false}
      destroyOnClose={true}
      maskClosable={false}
      width={"80%"}
    >
      <Spin spinning={vendorDiscountLoading}>
        <Form
          requiredMark={"optional"}
          name="basic"
          form={form}
          onFinish={onFinish}
          layout="vertical"
        >
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Row gutter={[20, 20]}>
                <Col span={16}>
                  <Form.Item
                    label="Resturant"
                    name="vendorID"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <Select
                      loading={resturantLoading}
                      disabled={id ? true : false}
                      showSearch
                      filterOption={true}
                      optionFilterProp="children"
                      onSearch={(val) => setFilter(val)}
                      value={selectedResturan}
                      onChange={(val) => setSelectedResturant(val)}
                      style={{ width: "100%" }}
                      placeholder="Find Resturant"
                      allowClear
                    >
                      {resturants?.success
                        ? resturants?.data?.records?.map((el) => (
                          <Option key={el.id} id={el.id} value={el.id}>
                            {el.name}
                          </Option>
                        ))
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Total Value"
                    name="totalValue"
                    rules={
                      type === "AMOUNT"
                        ? [{ required: true, message: "required" }]
                        : [
                          { required: true, message: "required" },
                          {
                            type: "number",
                            min: 0,
                            max: 100,
                            message: "must be percentage",
                          },
                        ]
                    }
                  >
                    {type === "AMOUNT" ? (
                      <InputNumber
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        onChange={getVendorValue}
                        addonAfter="IQD"
                        style={{ width: "100%" }}
                        min={0}
                      />
                    ) : (
                      <InputNumber
                        min={0}
                        max={100}
                        addonAfter="%"
                        parser={(value) => value.replace("%", "")}
                        onChange={getVendorValue}
                        style={{ width: "100%" }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Baly Value"
                    name="balyValue"
                    rules={
                      type === "AMOUNT"
                        ? [{ required: true, message: "required" }]
                        : [
                          { required: true, message: "required" },
                          {
                            type: "number",
                            min: 0,
                            max: 100,
                            message: "must be percentage",
                          },
                        ]
                    }
                  >
                    {type === "AMOUNT" ? (
                      <InputNumber
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        onChange={getVendorValue}
                        addonAfter="IQD"
                        style={{ width: "100%" }}
                        min={0}
                      />
                    ) : (
                      <InputNumber
                        min={0}
                        max={100}
                        addonAfter="%"
                        onChange={getVendorValue}
                        parser={(value) => value.replace("%", "")}
                        style={{ width: "100%" }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Vendor Value"
                    name="vendorValue"
                    rules={
                      type === "AMOUNT"
                        ? [{ required: true, message: "required" }]
                        : [
                          { required: true, message: "required" },
                          {
                            type: "number",
                            min: 0,
                            max: 100,
                            message: "must be percentage",
                          },
                        ]
                    }
                  >
                    {type === "AMOUNT" ? (
                      <InputNumber
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        addonAfter="IQD"
                        readOnly={true}
                        style={{ width: "100%" }}
                        min={0}
                      />
                    ) : (
                      <InputNumber
                        min={0}
                        max={100}
                        addonAfter="%"
                        readOnly={true}
                        parser={(value) => value.replace("%", "")}
                        style={{ width: "100%" }}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    // value={moment(startDate)}
                    label="Start Date"
                    name="startDate"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    // value={moment(endDate)}
                    label="End Date"
                    name="endDate"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="isAllProduct" valuePropName="checked">
                    <Checkbox
                      value={isAllProduct}
                      onChange={(e) => setIsAllProduct(e.target.checked)}
                    >
                      All Products in this Resturant ?
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Divider style={{ marginTop: 0 }} />
                  <Select
                    disabled={isAllProduct}
                    showSearch
                    filterOption={true}
                    optionFilterProp="children"
                    loading={productLoading}
                    value={selected}
                    onChange={(val) => setSelected(val)}
                    onSearch={(val) => setProductsFilter(val)}
                    style={{ width: "100%" }}
                    placeholder="Find Products"
                    allowClear
                  >
                    {!productLoading ? (
                      products?.success ? (
                        products?.data?.records?.map((el) => (
                          <Option
                            key={el.id}
                            id={el.id}
                            value={el.id}
                            disabled={isRepeated(el.id)}
                          >
                            {el.name}
                          </Option>
                        ))
                      ) : null
                    ) : (
                      <Spin spinning={true} />
                    )}
                  </Select>
                </Col>





                <Col span={24}>
                  <Divider style={{ marginTop: 0 }} />
                  <Search
                    value={search}
                    onChange={(val) => setSearch(val.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                    style={{ width: "100%" }}
                    placeholder="Find discounted products"
                    allowClear
                  >

                  </Search>
                </Col>




                <Col span={24}>
                  <div style={{ maxHeight: "24vh", overflow: "auto" }}>
                    <Space wrap>
                      {list.length > 0
                        ? list
                          .filter(el => el.name.includes(search))
                          .map(el => (
                            <Tag
                              key={el.id}
                              closable
                              onClose={() => {
                                setList(list.filter(item => item.id !== el.id));
                                setIsItemReomved(true);
                                form.setFieldsValue({ isAllProduct: false });
                              }}
                            >
                              {el.name}
                            </Tag>
                          ))
                        : null}
                    </Space>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={1}>
              <Divider type="vertical" style={{ height: "100%" }} />
            </Col>
            <Col span={11}>
              <Form.Item
                label="Schedule"
                name="schedule"
                rules={[{ required: true, message: "schedule is required" }]}
              >
                <CustomeCalender
                  value={schedule || null}
                  onChange={(val) => setSchedule(val)}
                  isAll={() => null}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Divider />
              <Form.Item>
                <Button
                  loading={isLoading || editLoading}
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};
