import create from "zustand";

export const useApologySettings = create((set) => ({
    isCreateModal: false,
    id: null,
    name: null,
    config: null,
    deepLink: null,
    msg: null,
    queryKey: "",
    type: null,

    created_at: null,
    updated_at: null,
    created_by: null,
    updated_by: null,
    status: null,

    ApologyType: null,
    setApologyType: (ApologyType) => set({ ApologyType }),


    setQueryKey: (queryKey) => set({ queryKey }),
    setId: (id) => set({ id }),
    setMsg: (msg) => set({ msg }),
    setName: (name) => set({ name }),
    setConfig: (config) => set({ config }),
    setDeepLink: (deepLink) => set({ deepLink }),
    setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
    setType: (type) => set({ type }),
    setCreatedAt: (created_at) => set({ created_at }),
    setupdatedAt: (updated_at) => set({ updated_at }),
    setCreatedBy: (created_by) => set({ created_by }),
    setUpdatedBy: (updated_by) => set({ updated_by }),
    setStatus: (status) => set({ status }),

}));
