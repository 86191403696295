import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
} from "antd";
import { SendOutlined } from "@ant-design/icons";
import { useOrderView } from "./store";
import { useMutation, useQuery } from "react-query";
import { createApologyMessage, getVoucherSettings } from "../../api";

const { Title } = Typography;
const { Option } = Select;

export const CreateApologyMsg = () => {
  const { isCreateModal, setIsCreateModal, order } = useOrderView();

  const { data: voucherSetting } = useQuery(
    ["voucherSettings", "", "APOLOGY", 500],
    getVoucherSettings,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const { mutate, isLoading } = useMutation(createApologyMessage, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateModal(false);
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onFinish = (values) => {
    let clientID = order.order.clientID;
    clientID.toString();
    mutate({
      settingID: values.settingID,
      users: `${order.order.phone}`,
      type: "default"
    });
  };

  return (
    <Modal
      title={<Title level={5}> Send Apology Message</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Voucher Setting"
              name="settingID"
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Select Voucher Setting"
              >
                {voucherSetting?.data?.records.map((val) => (
                  <Option key={val.id} value={val.id}>
                    {val.voucherTitle}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={24}>
            <Form.Item
              label="Voucher Code"
              name="code"
              rules={[{ required: true }]}
            >
              <Input placeholder={"Example Code"} />
            </Form.Item>
          </Col> */}
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading}
                type="primary"
                htmlType="submit"
                icon={<SendOutlined />}
              >
                Send
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
