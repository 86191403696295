import { Card, Input, Space, Button, Breadcrumb } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { PureTable } from "./table";
import { PureCreate } from "./create";
import { HistoryModal } from "./statusHistory";
import { ProductSchedule } from "./productSchedule";
import { DisableTime } from "./disableTime";
import { useProduct } from "./store";
import { useQuery } from "react-query";
import { getResturantOne } from "../../api";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import authFunc from "../../helper/auth";
import ProductFilters from "./filters";
import { ProductGroupSorting } from "./ProductGroupSorting";

const { Search } = Input;

export const ProductItemsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setIsCreateModal, setQueryKey, queryKey, isCreateSortModal,setIsCreateSortModal, } = useProduct();
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("q")) {
      setSearch(searchParams.get("q"));
      setQueryKey(searchParams.get("q"));
    } else {
      setSearch("");
      setQueryKey("");
    }
  }, [searchParams.get("q")]);

  const { data: resturant, refetch: refetchResturant } = useQuery(
    ["resturantOne", id],
    getResturantOne,
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (id) {
      refetchResturant();
    }
  }, [id]);

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Resturants</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate(-1)}>
          <a>Resturants List</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {resturant && resturant?.data?.arName}
        </Breadcrumb.Item>
        <Breadcrumb.Item>Products</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Space>
            <Search
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSearch={(val) => {
                searchParams.set("q", val);
                searchParams.set("page_num", 1);
                setSearchParams(searchParams);
                setQueryKey(val);
              }}
              style={{ width: 340 }}
              placeholder="Search by Name"
            />
            <ProductFilters vendorID={id} />
          </Space>
          <Space>
              {authFunc("VENDOR_CREATE") && (
              <Button
                onClick={() => setIsCreateSortModal(true)}
                type="primary"
              
              >
                Product Group Sorting
              </Button>
              )}
            {authFunc("PRODUCT_CREATE") && (
              <Button
                onClick={() => setIsCreateModal(true)}
                type="primary"
                icon={<PlusOutlined />}
              >
                Create New
              </Button>
            )}
          </Space>
        </div>
        <br />
        <PureTable vendorID={id} />
        <PureCreate vendorID={id} />
        <HistoryModal />
        <DisableTime />
        <ProductSchedule />
        <ProductGroupSorting vendorID={id}/>
      </Card>
    </div>
  );
};
